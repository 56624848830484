@font-face {
  font-family: Lato-Light;
  font-style: normal;
  font-weight: 300;
  src: url(./assets/fonts/Lato-Light.ttf);
}

@font-face {
  font-family: Lato-Regular;
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: Lato-Bold;
  font-style: normal;
  font-weight: 700;
  src: url(./assets/fonts/Lato-Bold.ttf);
}

@font-face {
  font-family: Lato-Semibold;
  font-style: normal;
  font-weight: 600;
  src: url(./assets/fonts/Lato-Semibold.ttf);
}
