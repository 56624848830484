.image-gallery-slide .image-gallery-image {
  object-fit: cover;
  height: 25em;
}

.image-gallery::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, transparent 25%);
  pointer-events: none;
  content: '';
  z-index: 1;
}

.image-gallery-bullets {
  /* It has "bottom: 20px" as a default. */
  bottom: auto;
  top: 4em;
}

.image-gallery-bullets .image-gallery-bullet {
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid transparent;
  box-shadow: none;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 5.75em;
  width: 3.75em;
}
