.StripeElement {
  font-family: Lato-Regular, sans-serif;
  background-color: white;
  margin: 0.5em 0;
  padding: 16px 12px;
  border: 1px solid #ababab;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
}

.StripeElement:first-child {
  margin-top: 0;
}

.StripeElement:last-child {
  margin-bottom: 0;
}

.StripeElementInline {
  margin: 0 0.5em;
}

.StripeElementInline:first-child {
  margin-left: 0;
}

.StripeElementInline:last-child {
  margin-right: 0;
}
